import React from "react";
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer id="footer">
      <div className="social-links">
        <a
          href="https://www.facebook.com/sonsleftbehind"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("./assets/icons/facebook.png")}
            alt="Facebook"
            className="social-icon"
          />
        </a>
        <a
          href="https://www.instagram.com/sonsleftbehind"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("./assets/icons/instagram.png")}
            alt="Instagram"
            className="social-icon"
          />
        </a>
        <a
          href="https://www.youtube.com/@sonsleftbehind"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("./assets/icons/youtube.png")}
            alt="YouTube"
            className="social-icon"
          />
        </a>
        <a
          href="https://www.tiktok.com/@sonsleftbehind"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require("./assets/icons/tiktok.png")}
            alt="TikTok"
            className="social-icon"
          />
        </a>
      </div>
      <div className="email">
        <a href="mailto:info@sonsleftbehind.com" className="email-link">
          info@sonsleftbehind.com
        </a>
      </div>
      <div className="copyright">&copy; {currentYear} Sons Left Behind</div>
    </footer>
  );
};

export default Footer;
